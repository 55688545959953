export const CURRENT_CONFIG = {

  // license
  appId: '151136', // You need to go to the development website to apply.
  appKey: 'ef7f96b525fdd17668616ed5f9dc48d', // You need to go to the development website to apply.
  appLicense: 'iVFHJSDboBLPXp5uX7/rMB9xosnIg1xP2xUQ3xFp69IAQsMgM+ZlzhcMEohh4li9U9+xXFTIbTeHlU2hKNTMo19Gbl+QlPtOzXiYL6H7rZLX+hBFjRUOcNt3kvcZ/OmdR/kf1hevkXzfCKgkyrdAWH6kLYonf9JXJWt0aS5m9GI=', // You need to go to the development website to apply.

  // http
  baseURL: 'https://hubapi.droneqube.com/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  websocketURL: 'wss://hubapi.droneqube.com/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'rtmp://rtmp.droneqube.com/live/', // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'viewer',
  rtspPassword: 'viewerpassword',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'Please enter the agora app id.',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'Please enter the agora channel.',

  // map
  // You can apply on the AMap website.
  amapKey: 'Please enter the amap key.',

}
